<style lang="scss">
#debug {
  display: none;
}
#frontend {
  .wo-wir-sind-all {
    .bg-grey {
      background: #f4f4f4;
      padding-top: 50px;
      margin-top: 30px;
      @media (max-width: $width-lg) {
        padding-bottom: 30px;
      }
      .headline-box-std {
        margin-top: 20px !important;
      }
      .container {
        @media (max-width: $width-lg) {
          max-width: 100%;
        }
      }
    }
    .standort-small {
      margin-top: 50px;
      display: none;
      @media (max-width: $width-lg) {
        display: block;
      }
      //border:1px solid red;
      .standort-box {
        //border:1px solid red;
        margin-bottom: 50px;
        .img-box {
          //border:1px solid blue;
          max-width: 70%;
          margin: 0 auto;
          //border-bottom:1px solid #000;
          overflow: hidden;
          padding-top: 30px;
          position: relative;
          @media (max-width: 650px) {
            border-bottom: 0;
          }
          @media (max-width: 600px) {
            max-width: 90%;
          }
          .circle-grey {
            width: 500px;
            height: 500px;
            background: #ededed;
            border-radius: 50%;
            position: absolute;
            left: 50%;
            margin-left: -250px;
            bottom: -250px;
            @media (max-width: $width-md) {
              width: 400px;
              height: 400px;
              margin-left: -200px;
              bottom: -100px;
            }
            @media (max-width: $width-sm) {
              display: none;
            }
          }
          img {
            max-width: 100%;
            display: block;
            margin: 0 auto;
            position: relative;
            z-index: 2;
          }
          .wien-small-img {
            bottom:-15px;
          }
        }
        .txt-box {
          text-align: center;
          padding: 20px 0;
          h4 {
            font-family: 'Helvetica Neue LT W05_75 Bold';
            font-size: 180%;
            margin-bottom: 20px;
          }
          h5 {
            font-family: 'Helvetica Neue LT W05_75 Bold';
            font-size: 120%;
            margin-bottom: 10px;
          }
          p {
            margin: 5px 0;

            a {
              display: block;
              &:hover {
                color: #000;
              }
            }
          }
        }
      }
    }
    .standort-img-outer {
      //border:1px solid red;
      position: relative;
      height: 700px;
      overflow: hidden;
      @media (max-width: $width-lg) {
        display: none;
      }

      .standort-img-inner {
        //border:1px solid blue;
        position: absolute;
        bottom: 0;

        img {
          max-width: 100%;

          @media (max-width: 1400px) {
          }
        }
      }
      .std-wien {
        left: 0;
        z-index: 1;
        bottom:-15px;
        @media (max-width: $width-xl) {
          transform: scale(0.8);
          bottom: -40px;
        }
      }
      .std-zagreb {
        left: 13%;
        z-index: 2;
        bottom: -50px;
        transform: scale(0.9);
        @media (max-width: 1400px) {
          transform: scale(0.8);
          bottom: -30px;
        }
        @media (max-width: $width-xl) {
          transform: scale(0.7);
          bottom: -65px;
        }
        img {
          max-width: 95%;
        }
      }
      .std-graz {
        left: 25%;
        z-index: 1;
        @media (max-width: $width-xl) {
          transform: scale(0.8);
          bottom: -50px;
        }
      }
      .std-ljubljana {
        right: 16%;
        z-index: 2;
        bottom: -20px;

        @media (max-width: 1400px) {
          transform: scale(0.9);
          bottom: -30px;
        }
        @media (max-width: $width-xl) {
          transform: scale(0.7);
          bottom: -65px;
          right: 14%;
        }
      }
      .std-klagenfurt {
        right: 0;
        z-index: 1;
        @media (max-width: $width-xl) {
          transform: scale(0.8);
          bottom: -30px;
        }
      }
      .cricle-grey {
        width: 700px;
        height: 700px;
        background: #ededed;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        margin-left: -350px;
        bottom: -100px;
        z-index: 0;
      }
    }
    .standorte-big-nav {
      // border:1px solid blue;
      @media (max-width: $width-lg) {
        display: none;
      }
      #standorte-nav {
        //border:1px solid red;
        margin-top: 20px;
        margin-bottom: 20px;
        li {
          width: 20%;
          text-align: center;
          // border:1px solid red;
          .nav-link {
            margin: 0 auto;
            display: inline-block;
            border: 0;
            background: none;
            font-family: 'Helvetica Neue LT W05_75 Bold';
            font-size: 110%;
            transition: $transition-std;
            color: #6f6f6f;
            //border:1px solid blue;
            padding: 0px 10px;
            height: 80px;
            &:hover {
              font-size: 140%;
              color: #000;
            }
          }
          .active {
            font-size: 140%;
            color: #000;
          }
        }
      }
      .tab-content {
        border-top: 1px solid #000;
        padding: 60px 0px 50px 0px;
        border-bottom: 1px solid #000;
        //text-align: center;
        h4 {
          font-family: 'Helvetica Neue LT W05_75 Bold';
          font-size: 120%;
          margin-bottom: 10px;
        }
        p {
          margin: 5px 0;

          a {
            display: block;
            &:hover {
              color: #000;
            }
          }
        }
        .tab-pane {
          //display:inline-block;
        }
        .txt-v1 {
          //display:inline-block;
          display: inline-block;
          text-align: center;
          @media (max-width: 1500px) {
            display: block;
          }
        }
        .txt-v2 {
          margin-left: 20%;
          //display:inline-block;
          display: inline-block;
          text-align: center;
          @media (max-width: 1500px) {
            display: block;
            margin-left: 0;
          }
        }
        .txt-v3 {
          margin-left: 42%;
          //display:inline-block;
          display: inline-block;
          text-align: center;
          @media (max-width: 1500px) {
            display: block;
            margin-left: 0;
          }
        }
        .txt-v4 {
          margin-left: 59%;
          //display:inline-block;
          display: inline-block;
          text-align: center;
          @media (max-width: 1500px) {
            display: block;
            margin-left: 0;
          }

         
        }
        .txt-v5 {
          margin-left: 80%;
          //display:inline-block;
          display: inline-block;
          text-align: center;
          @media (max-width: 1500px) {
            display: block;
            margin-left: 0;
          }
        }
         
      }
    }
    .h4-margin {
      margin-top: 50px;
    }
    .border-bottom-1px-black {
      border-bottom: 1px solid #000;
      @media (max-width: $width-lg) {
        border-bottom: 0;
      }
    }
  }
}
</style>

<template>
  <SocialMediaIconBox />
  <div class="wo-wir-sind-all">
    <!--<div class="header-intro-generel-box">
    <div class="inner-image-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/header-buchdruckerei-styria.jpg')})` }">
       <div class="container title-box">
          <h1>
            <span></span>
          </h1>
        </div>
      </div>
  </div>
  <div class="header-intro-generel-box header-intro-generel-box-v2 ">
    <div class="inner-image-box" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/header-styria-hq.jpg')})` }">
       <div class="container title-box">
          <h1>
            <span></span>
          </h1>
        </div>
      </div>
  </div>-->
    <div class="bg-grey">
      <div class="container">
        <div class="headline-box-std">
          <div class="headline-inner">
            <div class="breadcrumb">
              <span>{{ $t('wo-wir-sind.breadcrumb') }}</span>
            </div>
            <h1>{{ $t('wo-wir-sind.title') }}</h1>
          </div>
        </div>
        <div class="standort-img-outer">
          <div class="standort-img-inner std-wien">
            <img src="@/assets/img/ueber-uns/std-wien-v2.png" alt="Standort Wien" />
          </div>
          <div class="standort-img-inner std-zagreb">
            <img src="@/assets/img/ueber-uns/std-zagreb-v2.png" alt="Standort Zagreb" />
          </div>
          <div class="standort-img-inner std-graz">
            <img src="@/assets/img/ueber-uns/std-graz.png" alt="Standort Graz" />
          </div>
          <div class="standort-img-inner std-ljubljana">
            <img src="@/assets/img/ueber-uns/std-ljubljana.png" alt="Standort Wien" />
          </div>
          <div class="standort-img-inner std-klagenfurt">
            <img src="@/assets/img/ueber-uns/std-klagenfurt.png" alt="Standort Klagenfurt" />
          </div>
          <div class="cricle-grey"></div>
        </div>
        <div class="standort-small">
          <div class="standort-box">
            <div class="img-box">
              <img src="@/assets/img/ueber-uns/std-wien-v2.png" class="wien-small-img" alt="Standort Wien" />
              <div class="circle-grey"></div>
            </div>
            <div class="txt-box">
              <h4>{{ $t('wo-wir-sind.wien') }}</h4>
              <h5>Styria Media Group AG</h5>
              <p><a href="https://goo.gl/maps/z3Li8uk82bBwYdWw9" target="_blank">Lobkowitzplatz 1, 1010 Wien </a></p>
              <p><a href="https://goo.gl/maps/w3cNL1hpXS8YKxLb7" target="_blank">Hainburger Straße 33, 1030 Wien </a></p>
              <p><a href="mailto:mediagroup@styria.com">mediagroup@styria.com</a></p>
            </div>
          </div>
          <!-- -->
          <div class="standort-box">
            <div class="img-box">
              <img src="@/assets/img/ueber-uns/std-zagreb-v2.png" alt="Standort Zagreb" />
              <div class="circle-grey"></div>
            </div>
            <div class="txt-box">
              <h4>{{ $t('wo-wir-sind.zagreb') }}</h4>
              <h5>Tiskara Zagreb d.o.o.</h5>
              <p><a href="https://goo.gl/maps/sH2Mi2GrbYc46pmS7" target="_blank">Oreškovićeva 6H/1, 10000 Zagreb</a></p>
              <p><a href="mailto:marketing@tiskarazagreb.hr">marketing@tiskarazagreb.hr</a></p>
              <h5 class="h4-margin">Njuškalo d.o.o.</h5>
              <p><a href="https://goo.gl/maps/jYgMmKJiGNz9UEDQ6" target="_blank">Miroslava Miholića 2, 10000 Zagreb</a></p>
              <p><a href="mailto:podrska@njuskalo.hr">podrska@njuskalo.hr</a></p>
            </div>
          </div>
          <div class="standort-box">
            <div class="img-box">
              <img src="@/assets/img/ueber-uns/std-graz.png" alt="Standort Graz" />
              <div class="circle-grey"></div>
            </div>
            <div class="txt-box">
              <h4>{{ $t('wo-wir-sind.graz') }}</h4>
              <h5>Styria Media Group AG</h5>
              <p><a href="https://goo.gl/maps/FSu4LmUUYq11n2cWA" target="_blank">Gadollaplatz 1, 8010 Graz</a></p>
              <p><a href="mailto:mediagroup@styria.com">mediagroup@styria.com</a></p>
            </div>
          </div>
          <div class="standort-box">
            <div class="img-box">
              <img src="@/assets/img/ueber-uns/std-ljubljana.png" alt="Standort Ljubjana" />
              <div class="circle-grey"></div>
            </div>
            <div class="txt-box">
              <h4>{{ $t('wo-wir-sind.ljubljana') }}</h4>
              <h5>Styria Digital marketplaces d.o.o.</h5>
              <p><a href="https://goo.gl/maps/Eics51dkJx2u9bjv9" target="_blank">Verovškova ulica 55, 1000 Ljubljana</a></p>
              <p><a href="mailto:mediagroup@styria.com">mediagroup@styria.com</a></p>
              <h5 class="h4-margin">Styria-media si, d. o. o.</h5>
              <p><a href="https://goo.gl/maps/bhWDnGJAtGZi5N168" target="_blank">Italijanska cesta 8, SI-1000 Ljubljana </a></p>
              <p><a href="mailto:mediagroup@styria.com">mediagroup@styria.com</a></p>
            </div>
          </div>
          <div class="standort-box">
            <div class="img-box">
              <img src="@/assets/img/ueber-uns/std-klagenfurt.png" alt="Standort Wien" />
              <div class="circle-grey"></div>
            </div>
            <div class="txt-box">
              <h4>{{ $t('wo-wir-sind.klagenfurt') }}</h4>
              <h5>Styria Media Group AG</h5>

              <p><a href="https://goo.gl/maps/FzWJCEWFqj4A2t7c6" target="_blank">Hasnerstraße 2, 9020 Klagenfurt </a></p>
              <p><a href="mailto:mediagroup@styria.com">mediagroup@styria.com</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="standorte-big-nav">
        <ul class="nav" id="standorte-nav" role="tablist">
          <li class="nav-item">
            <button class="nav-link " id="std-wien-tab" data-bs-toggle="pill" data-bs-target="#std-wien" type="button" role="tab" aria-controls="std-wien" aria-selected="false">{{ $t('wo-wir-sind.wien') }}</button>
          </li>
          <li class="nav-item">
            <button class="nav-link" id="std-zagreb-tab" data-bs-toggle="pill" data-bs-target="#std-zagreb" type="button" role="tab" aria-controls="std-zagreb" aria-selected="false">{{ $t('wo-wir-sind.zagreb') }}</button>
          </li>
          <li class="nav-item">
            <button class="nav-link active" id="std-graz-tab" data-bs-toggle="pill" data-bs-target="#std-graz" type="button" role="tab" aria-controls="std-graz" aria-selected="true">{{ $t('wo-wir-sind.graz') }}</button>
          </li>
          <li class="nav-item">
            <button class="nav-link" id="std-ljubljana-tab" data-bs-toggle="pill" data-bs-target="#std-ljubljana" type="button" role="tab" aria-controls="std-ljubljana" aria-selected="false">{{ $t('wo-wir-sind.ljubljana') }}</button>
          </li>
          <li class="nav-item">
            <button class="nav-link" id="std-klagenfurt-tab" data-bs-toggle="pill" data-bs-target="#std-klagenfurt" type="button" role="tab" aria-controls="std-klagenfurt" aria-selected="false">{{ $t('wo-wir-sind.klagenfurt') }}</button>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane fade" id="std-wien" role="tabpanel" aria-labelledby="std-wien-tab">
            <div class="txt-v1">
              <h4>Styria Media Group AG</h4>
              <p><a href="https://goo.gl/maps/z3Li8uk82bBwYdWw9" target="_blank">Lobkowitzplatz 1, 1010 Wien </a></p>
              <p><a href="https://goo.gl/maps/w3cNL1hpXS8YKxLb7" target="_blank">Hainburger Straße 33, 1030 Wien </a></p>
              <p><a href="mailto:mediagroup@styria.com">mediagroup@styria.com</a></p>
            </div>
          </div>
          <div class="tab-pane fade" id="std-zagreb" role="tabpanel" aria-labelledby="std-zagreb-tab">
            <div class="txt-v2">
              <h4>Tiskara Zagreb d.o.o.</h4>
              <p><a href="https://goo.gl/maps/sH2Mi2GrbYc46pmS7" target="_blank">Oreškovićeva 6H/1, 10000 Zagreb</a></p>
              <p><a href="mailto:marketing@tiskarazagreb.hr">marketing@tiskarazagreb.hr</a></p>
              <h4 class="h4-margin">Njuškalo d.o.o.</h4>
              <p><a href="https://goo.gl/maps/jYgMmKJiGNz9UEDQ6" target="_blank">Miroslava Miholića 2, 10000 Zagreb</a></p>
              <p><a href="mailto:podrska@njuskalo.hr">podrska@njuskalo.hr</a></p>
            </div>
          </div>
          <div class="tab-pane fade show active" id="std-graz" role="tabpanel" aria-labelledby="std-graz-tab">
            <div class="txt-v3">
              <h4>Styria Media Group AG</h4>
              <p><a href="https://goo.gl/maps/FSu4LmUUYq11n2cWA" target="_blank">Gadollaplatz 1, 8010 Graz</a></p>
              <p><a href="mailto:mediagroup@styria.com">mediagroup@styria.com</a></p>
            </div>
          </div>
          <div class="tab-pane fade" id="std-ljubljana" role="tabpanel" aria-labelledby="std-ljubljana-tab">
            <div class="txt-v4">
              <h4>Styria Digital marketplaces d.o.o.</h4>
              <p><a href="https://goo.gl/maps/Eics51dkJx2u9bjv9" target="_blank">Verovškova ulica 55, 1000 Ljubljana</a></p>
              <p><a href="mailto:mediagroup@styria.com">mediagroup@styria.com</a></p>
              <h4 class="h4-margin">Styria-media si, d. o. o.</h4>
              <p><a href="https://goo.gl/maps/bhWDnGJAtGZi5N168" target="_blank">Italijanska cesta 8, SI-1000 Ljubljana </a></p>
              <p><a href="mailto:mediagroup@styria.com">mediagroup@styria.com</a></p>
            </div>
          </div>
          <div class="tab-pane fade" id="std-klagenfurt" role="tabpanel" aria-labelledby="std-klagenfurt-tab">
            <div class="txt-v5">
              <h4>Styria Media Group AG</h4>
              <p><a href="https://goo.gl/maps/FzWJCEWFqj4A2t7c6" target="_blank">Hasnerstraße 2, 9020 Klagenfurt </a></p>
              <p><a href="mailto:mediagroup@styria.com">mediagroup@styria.com</a></p>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center padding-tb-l border-bottom-1px-black">
        <div class="col-lg-8 ">
          <p class="text-center">
            {{ $t('wo-wir-sind.text-v1') }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import { mdiChevronUp } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Wo wir sind | Styria Media Group',
      keywords: ['standorte', 'graz', 'adresse', 'graz', 'styria', 'international', 'headquatrter', 'kroatien', 'slowenien'],
      description: 'Graz, Wien, Zagreb, Ljubljana, Klagenfurt: Österreich, Kroatien und Slowenien sind der Kernmarkt der Styria Media Group.',
      image: require('@/assets/img/og/ueber-uns.png'),
    },
    en: {
      title: 'Where we are | Styria Media Group',
      keywords: ['location', 'graz', 'address', 'graz', 'styria', 'international', 'headquatrter', 'croatia', 'slovenia'],
      description: 'Graz, Vienna, Zagreb, Ljubljana, Klagenfurt: Austria, Croatia and Slovenia are the core market of the Styria Media Group.',
      image: require('@/assets/img/og/ueber-uns.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    Footer,
  },
  setup() {
    return {
      mdiChevronUp,
    };
  },
});
</script>
